@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Add any global styles here */
body {
  font-family: 'Arial', sans-serif;
}

.cookie-popup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  right: 1rem;
  max-width: 28rem;
  background: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 50;
  border: 1px solid #e5e7eb;
}

.cookie-popup-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cookie-popup-text {
  flex: 1;
}

.cookie-popup-title {
  color: #1f2937;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.cookie-popup-description {
  font-size: 0.875rem;
  color: #4b5563;
}

.cookie-popup-button {
  margin-left: 1rem;
  background-color: #2563eb;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  cursor: pointer;
  border: none;
  transition: background-color 0.2s;
}

.cookie-popup-button:hover {
  background-color: #1d4ed8;
}

@media (min-width: 768px) {
  .cookie-popup {
    left: 1rem;
    right: auto;
  }
}